
import React, { useEffect, useState } from "react";
import Parallax from "../Common/Parallax";
import CommonObj from "../CommonObj";
import hidePreLoad from "../../assets/js/Components/hidePreLoad";
import parallax, { scrollRefresh, } from "../../assets/js/Components/parallax";
import Section from "../Section/Section";
import { Helmet } from "react-helmet";
import PreLoad from "../Common/preload";
import Hero from '../Common/Hero';
import BlogListTemplate from '../Common/blog-list-template';
import Dropdown from "../../assets/js/Components/dropdown";

export default function Blog(props) {
  const parse = require("html-react-parser");
  const [page, setPage] = useState();
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const handleSearch = (event) => {
    setCurrentPage(1);
    setSearchText(event.target.value);
  };
  const handleSort = (event) => {
    setSort(event);
    setCurrentPage(1);
  };
  const handleFilter = (event) => {
    setFilter(event);
    setCurrentPage(1);
  };
  const fetchData = async () => {
    let pageName = CommonObj.PageNameList.Blogs;
    let res = await fetch(CommonObj.Endpoints.GetPage, {
      method: 'POST',
      body: JSON.stringify(pageName),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let page = await res.json()
    setPage(page);
  }


  useEffect(() => {
    // fetch call used to be here
    fetchData();

  }, []);
  useEffect(() => {
    if (page != null) {
      parallax();
      scrollRefresh();
    }
    window.scrollTo(0, 0);
    hidePreLoad();
  }, []);

  if (page != null) {

    return (
      <>
        {<Section PageName={page.name} async>
          <Helmet>
            <title>{page.title}</title>
            <meta name="description" content={page.metaDescription} />
            <meta name="keywords" content={page.metaKeywords} />
          </Helmet>
          <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
          {<Hero landingpage={false} heading1={page.shortDesc && parse(page.shortDesc)} resources={props.resources} contactUs={false}></Hero>}
          <div className="pb15 bg-white ">
            <div className="customBreadcrumbsBg container search-pt search-pb">
              <div className="search-container">
                <div className="search-box">
                  <input type="text" className="search-input" placeholder="Search Blog" onChange={handleSearch} />
                </div>
                <Dropdown options={[CommonObj.SortType.Decending,
                CommonObj.SortType.Ascending,]}
                  placeholder="Sort by" onSelect={handleSort} />
                <Dropdown
                  options={[
                    CommonObj.FilterType.All,
                    CommonObj.FilterType.Last_3_Months,
                    CommonObj.FilterType.Last_6_Months,
                  ]}
                  placeholder="Filter by"
                  onSelect={handleFilter}
                />
              </div>
            </div>
          </div>
          <BlogListTemplate heading={CommonObj.ResourceKeyDefaults['BLOGS-TITLE']}
            bottomspace={false} searchText={searchText} sort={sort} filter={filter} currentPage={currentPage} setCurrentPage={setCurrentPage} ></BlogListTemplate>
        </Section>}

      </>
    );
  }
  else {
    return <PreLoad></PreLoad>;
  }
}


