import React, { useState, useEffect } from 'react';
import CommonObj from '../../../CommonObj';
import heroCarousel from '../../../../assets/js/Components/heroCarousel';
import carousel from '../../../../assets/js/Components/carousel';
import faq from '../../../../assets/js/Components/faq';
import { collaspeMenu } from '../../../../assets/js/Components/navigation';
import hidePreLoad from '../../../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../../../assets/js/Components/parallax';
import resizeWindow from '../../../../assets/js/Components/resizeWindow';
import formInit from '../../../../assets/js/Components/formInit';
import Parallax from '../../../Common/Parallax';
import Hero from '../../../Common/Hero';
import TestimonialExcerptSection from '../../../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../../../Common/TestimonialTemplate';
import BlogTemplate from '../../../Common/blog-template';
import FAQTemplate from '../../../Common/faq-template';
import PageNotFound from '../../../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../../../Section/Section';
import InfographicsTemplate from '../../../Common/InfographicsTemplate';
import '../../../../components/loader.css'
import PreLoad from '../../../Common/preload';
import CommonFunc from '../../../../assets/js/Components/CommonFunc';
import UtilitiesIconSection from '../../utilities-icon-section'; 
import UtilitiesIconLinkSection from '../../utilities-iconlink-section';
import UtilitiesPanelSection from '../../utilities-panel-section';
import GOC_Parrallex_container from '../../../../components/global-brand-solutions/GOC_Parrallex_container';
import StaticResourceText from '../../../Format/static-resource-text';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../Common/breadcrumb';


function SWS_OT_Utilities({ resources }) 
{
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(true);
  
  const fetchData = async () => {
      let pageName = CommonObj.PageNameList.SWSOperationTransformationUtilities;
      let res = await fetch(CommonObj.Endpoints.GetPage, {
          method: 'POST',
          body: JSON.stringify(pageName),
          headers: {
              'Content-Type': 'application/json',
          }
      })
      let page = await res.json()
      setPage(page);
      CommonFunc(page);
  }
  function PageScroller()
  {
    const timer = setTimeout(() => 
    {
      setLoading(false);
    }, 3000);
  }

  useEffect(() => {
      // fetch call used to be here
      fetchData();
      collaspeMenu();
      PageScroller();
     

  }, []);
  useEffect(() => {
    if (page != null) {
      heroCarousel();
      carousel();
      faq();
      formInit();
      parallax();
      scrollRefresh();
    }
    window.scrollTo(0, 0);
    hidePreLoad();
    resizeWindow();
  },[loading]);

  if (page != null && page!=undefined) {
    let casestudies = page.contents.filter((x) => x.type == CommonObj.ContentType.CaseStudies);
    let testimonials = page.contents.filter((x) => x.type == CommonObj.ContentType.Testimonials);
    let icons = page.contents.filter((x) => x.type == CommonObj.ContentType.Icons);
    let faqs = page.contents.filter((x) => x.type == CommonObj.ContentType.FAQS);
    const parse = require("html-react-parser");
    let blogs = page.contents.filter((x) => x.type == CommonObj.ContentType.Blogs);
    let panels = page.contents.filter((x) => x.type == CommonObj.ContentType.Panels && x.tags == "SWS-OT-UTIL-PANEL-RRD-ADVANTAGE");
    let explorePanels = page.contents.filter((x) => x.type == CommonObj.ContentType.Panels && x.tags == "SWS-OT-UTIL-PANEL-EXPLORE");
    let outcomeicons = page.contents.filter((x) => x.type == CommonObj.ContentType.Thumbnails);
    let infographics = page.contents.filter((x) => x.type == CommonObj.ContentType.Infographics);
    let heroCarouselList = page.contents.filter((x) => x.type == CommonObj.ContentType.Carousels);
    let PortfolioIcons = page.contents.filter((x) => x.type == CommonObj.ContentType.Icons && x.tags == "SWS-OT-UTIL-SERVICE-PORTFOLIO");
    let BrandIcons = page.contents.filter((x) => x.type == CommonObj.ContentType.Icons && x.tags == "SWS-OT-UTIL-BRAND-CUSTOMER-EXPERIENCE");
    let TechnologyIcons = page.contents.filter((x) => x.type == CommonObj.ContentType.Icons && x.tags == "SWS-OT-UTIL-TECHNOLOGY-TRANSFORMATION");
    let InnovationIcons = page.contents.filter((x) => x.type == CommonObj.ContentType.Icons && x.tags == "SWS-OT-UTIL-INNOVATION");
    return (
      <>
       {loading ? (<PreLoad></PreLoad>) : (
       <Section PageName={page.name} async>
         <Helmet>
          <title>{page.title}</title>
          <meta name="description" content={page.metaDescription} />
          {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
         </Helmet>
        <Parallax pageheroimage={page.image ? page.image : ""}></Parallax>
        <Hero landingpage={false}  category={page.heading} heading1={page.shortDesc} carouselList={heroCarouselList} resources={resources}></Hero>
        <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ""} ></Parallax>
        <BreadCrumb page={page?.name}/>
        <div className="bg-gray mat-10" async>
          <section className="container">
            <div className="inner">
              <div className="center-content para90" async>
              <StaticResourceText resources={resources} reskey={"SWS-OT-UTIL-STREAMLINE-OPERATIONS"}></StaticResourceText>
              </div>
            </div>
          </section>
          </div>
        <div className="bg-white mt-1" async>
          <section className="container">
            <div className="inner">
              <div className="center-content para90" async>
                 {page.longDesc && parse(page.longDesc)}
                {!!infographics.length && (<InfographicsTemplate infographics={infographics}></InfographicsTemplate>)}
              </div>
            </div>
          </section>
        </div>
        {<GOC_Parrallex_container resources={resources}
            Icon1={PortfolioIcons}
            Icon2={BrandIcons}
            Icon3={TechnologyIcons}
            Icon4={InnovationIcons}
          ></GOC_Parrallex_container>}
           <UtilitiesPanelSection panels={panels} ></UtilitiesPanelSection>
        {!!outcomeicons.length && (<UtilitiesIconLinkSection icons={outcomeicons} resources={resources}></UtilitiesIconLinkSection>)}
        <UtilitiesPanelSection panels={explorePanels} isNotExplore={false} isUtilExplore={true} ></UtilitiesPanelSection>
        {!!testimonials.length && (<TestimonialExcerptSection testimonials={testimonials} resources={resources}></TestimonialExcerptSection>)}
        {!!casestudies.length && (<TestimonialTemplate testimonials={casestudies} notopspace={true}  resources={resources} isCsTitle={true} csTitle={"SWS-OT-UTIL-CLIENT-SUCCESS-TITLE"}></TestimonialTemplate>)}
        {!!blogs.length && (<BlogTemplate bgblue={false} alignleft={false}  blogs={blogs} notopspace={true} resources={resources}></BlogTemplate>)}
        <StaticResourceText resources={resources} reskey={"SWS-OT-UTIL-CLIENT-TESTIMONIALS"}></StaticResourceText>
        {!!faqs.length && (<FAQTemplate faqs={faqs} resources={resources} ></FAQTemplate>
        )}
      </Section>
      )}
      </>
    );
  } 
  else {
    return ( 
    <>
    <PreLoad></PreLoad>
      {/* {loading ?  (<PreLoad></PreLoad>) : (
          <PageNotFound></PageNotFound>
      )} */}
      </>);
  }
}

export default SWS_OT_Utilities;
