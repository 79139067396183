import React, { useEffect, useState } from 'react';
import Hero from '../Common/Hero';
import formInit from '../../assets/js/Components/formInit';
import parallax, { scrollRefresh } from '../../assets/js/Components/parallax';
import { mobileMenu, navigationInit, removeMobileMenu } from '../../assets/js/Components/navigation';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import AboutusSection2 from '../about-us/AboutUsSection2';
import CommonObj from '../CommonObj';
import Parallax from '../Common/Parallax';
import TestimonialTemplate from '../Common/TestimonialTemplate';
import BlogTemplate from '../Common/blog-template';
import FAQTemplate from '../Common/faq-template';
import faq from '../../assets/js/Components/faq';
import PageNotFound from '../Common/PageNotFound';
import InfographicsTemplate from '../Common/InfographicsTemplate';
import TestimonialExcerptSection from '../Common/TestimonialExcerptSection';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import '../../components/loader.css';
import PreLoad from '../Common/preload';
import CommonFunc from '../../assets/js/Components/CommonFunc';
import { scrollMemory } from '../../assets/js/Components/parallax';
import BreadCrumb from '../Common/breadcrumb';
import StaticResourceText from '../Format/static-resource-text';
const AboutUs = (props) => {

    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.AboutUs;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
    }
    function PageScroller()
    {
      const timer = setTimeout(() => 
      {
        setLoading(false);
       
      }, 3000);
    }

    useEffect(() => {
        // fetch call used to be here
       fetchData();
       PageScroller();
       
    }, []);

    useEffect(() => {
        if (page != null) 
        {
           heroCarousel();
            formInit();
            parallax();
            faq();
            scrollRefresh();
            return () => 
            {
                scrollMemory(); 
            };
        }
        window.scrollTo(0, 0);
        hidePreLoad();
        resizeWindow();
       
    });

    if (page != null) {
        const parse = require('html-react-parser');
        let articles = page.contents.filter(x => x.type == CommonObj.ContentType.Articles);
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
        let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);

        return (
            <>
                {loading ? (<PreLoad></PreLoad>) : (
                    <Section PageName={page.name} async>
                        <Helmet>
                            <title>{page.title}</title>
                            <meta name="description" content={page.metaDescription} />
                            <meta name="keywords" content={page.metaKeywords} />
                            {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
                        </Helmet>
                        <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
                        <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ''}></Parallax>
                        <Hero landingpage={false} heading1={page.shortDesc && parse(page.shortDesc)} carouselList={heroCarouselList} resources={props.resources}></Hero>
                        <BreadCrumb page={page?.name}/>
                        {page.longDesc && parse(page.longDesc)}
                        {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                        {!!articles.length && <AboutusSection2 articles={articles} resources={props.resources}></AboutusSection2>}
                                
                        {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={props.resources}></TestimonialExcerptSection>}
                        {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} resources={props.resources}></TestimonialTemplate>}
                        {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={false} resources={props.resources}></BlogTemplate>}
                        {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={props.resources}></FAQTemplate>}
                    </Section>
                )}
            </>

        )
    }
    else {
        return (
            <>
            <PreLoad></PreLoad>
            </>
        )

    }
}



export default AboutUs;