import React from "react";
import StaticResourceText from "../Format/static-resource-text";

const AboutUsSection2 = ({ articles,resources }) => {  /* NOSONAR */
  const parse = require("html-react-parser");

  return (
    <div className="bg-peach " async>
      <section className="container">
        {articles &&
          articles.map((article, i) => {
            return (
              <div className="inner" key={article.title}>
                <div className="para90">
                  <h2 className="align-center">{article.title}</h2>
                  <p>{parse(article.shortDesc)}</p>
                </div>
                <div className="align-center" style={{ marginTop: "2rem" }}>
                  <img src={article.image} alt={article.title} />
                </div>
              </div>
            );
          })}
           <StaticResourceText resources={resources} reskey={"ABOUTUS-VIEW-AWARDS"}></StaticResourceText> 
      </section>
     
    </div>
  );
};
export default AboutUsSection2;
